import React from 'react'

export default ({ ...props }) => (
  <svg
    {...props}
    width='89'
    height='89'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 88.96 88.96'
  >
    <g id='Layer_2' data-name='Layer 2'>
      <g id='Layer_1-2' data-name='Layer 1'>
        <polygon points='88.97 3.18 85.78 0 44.48 41.3 3.18 0 0 3.18 41.3 44.48 0 85.78 3.18 88.97 44.48 47.66 85.78 88.97 88.97 85.78 47.66 44.48 88.97 3.18' />
      </g>
    </g>
  </svg>
)
