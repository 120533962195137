import React from 'react'

export default ({ ...props }) => {
  return (
    <svg
      width='89'
      height='89'
      {...props}
      xmlns='http://www.w3.org/2000/svg'
      viewBox='0 0 88.96 88.96'
    >
      <g id='Layer_2' data-name='Layer 2'>
        <g id='Layer_1-2' data-name='Layer 1'>
          <polygon points='88.96 42.23 8.61 42.23 47.66 3.18 44.48 0 0 44.48 44.48 88.97 47.66 85.78 8.61 46.73 88.96 46.73 88.96 42.23' />
        </g>
      </g>
    </svg>
  )
}
